import React, { useEffect } from 'react';
import BasicLayout from '../../layout/BasicLayout';
import Loading from '../../components/Loading';
import HeadEncuesta from '../../components/HeadEncuesta';
import FormularioDocente from '../../components/FormularioDocente';

//Redux
import { connect } from 'react-redux';
import { obtenerFormulario } from '../../_actions/formularioActions';

import './EncuestaDocente.scss';


const EncuestaDocente = props => {

  const { loading, formulario, obtenerFormulario } = props;

  useEffect(() => {
    obtenerFormulario('encuesta-docente-62e9553a-b59b-11ea-87a3-678bf9a08d59')
  }, [])

  return ( 
    <BasicLayout header={false} title={'Encuesta tutor  '}>
      <div className="encuesta-docente">
      {loading ? (
          <Loading />
        ) : (
          <>
            <div className="main-encuesta shadow">
              <HeadEncuesta
                title={formulario.nombre_formulario}
                descripcion={formulario.descripcion}
              />
              <div className="body-encuesta bg-white p-md-5 pt-md-1 p-2">
                <FormularioDocente/>
              </div>
              <div className="foot">
                © 2020 INEDI Posgrados &reg; Privacy & Cookies
              </div>
            </div>
          </>
        )}
      </div>
    </BasicLayout>
    
   );
}

const mapStateToProps = state => ({
  formulario: state.formulario.formulario,
  loading: state.formulario.loading
})

const mapDispatchToProps = {
  obtenerFormulario
}
 
export default connect(mapStateToProps, mapDispatchToProps)(EncuestaDocente);