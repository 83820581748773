import { 
  OBTENER_FOMR_LOGIN,
  OBTENER_FORM_LOGIN_EXITO,
  OBTENER_FORMULARIO,
  OBTENER_FORMULARIO_EXITO,
  GUARDAR_RESPUESTAS_EXITO,
  ACTUALIZAR_GUARDADO
 } from '../_actions';

const initialState = {
  formulario: {},
  envioForm: false,
  loading: true,
  error: null
}

export default function(state = initialState , action) {
  switch (action.type) {
    case OBTENER_FOMR_LOGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case OBTENER_FORMULARIO:
      return {
        ...state,
        loading: true,
      }
    case OBTENER_FORMULARIO_EXITO:
      return {
        ...state,
        loading: false,
        formulario: action.payload
      }
    case OBTENER_FORM_LOGIN_EXITO:
      return {
        ...state,
        loading: false,
        formulario: action.payload
      }
    case GUARDAR_RESPUESTAS_EXITO:
      return {
        ...state,
        envioForm: true
      }
    case ACTUALIZAR_GUARDADO:
      return {
        ...state,
        envioForm: false
      }
    default:
      return state;
  }
}