import React from "react";
import { Form } from "react-bootstrap";
import SubPeguntas from "../SubPreguntas";
import SimpleRating from "../SimpleRating";

const ListaPreguntasDocentes = props => {

  const { formulario } = props

  return (
    <div className="lista-preguntas">
      {formulario.preguntas.map((pregunta, index) => (
        <Pregunta key={pregunta.id_pregunta_reporte} index={index} pregunta={pregunta} />
      ))}
    </div>
  );
};

export default ListaPreguntasDocentes;

const Pregunta = ({ pregunta, index }) => {

  if (pregunta.propuestas) {
    return (
      <Form.Group className="pregunta">
        <Form.Label>{index + 1}.- {pregunta.pregunta}</Form.Label>
        <SimpleRating/>
        <SubPeguntas />
      </Form.Group>
    );
  }

  return (
    <Form.Group className="pregunta">
      <Form.Label>{index + 1}.- {pregunta.pregunta}</Form.Label>
      <Form.Control as="textarea" />
    </Form.Group>
  );
};
