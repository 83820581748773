import {
  OBTENER_FOMR_LOGIN,
  OBTENER_FORM_LOGIN_EXITO,
  OBTENER_FORMULARIO,
  OBTENER_FORMULARIO_EXITO,
  GUARDAR_RESPUESTAS_EXITO,
  GUARDAR_RESPUESTAS,
  ACTUALIZAR_GUARDADO
} from "./index";


import { authAxios } from "../config/authAxios";

const loading = (type) => {
  return {
    type,
  };
};

const updateActiveForm = (form) => {
  return {
    type: OBTENER_FORM_LOGIN_EXITO,
    payload: form,
  };
};

export const consultadoUrl = (url) => {
  return async (dispatch) => {
    dispatch(loading(OBTENER_FOMR_LOGIN));

    try {
      const response = await authAxios.get(
        `/admin/rptet_confirmacionUrl/${url}`
      );

      console.log(response.data);
      dispatch(updateActiveForm(response.data));
    } catch (error) {}
  };
};

const formularioObtenido = (form) => {
  return {
    type: OBTENER_FORMULARIO_EXITO,
    payload: form,
  };
};

export const obtenerFormulario = (url) => {
  return async (dispatch) => {
    dispatch(loading(OBTENER_FORMULARIO));

    try {
      const formulario = await authAxios.get(
        `/admin/rptet_mostrarFormularioURL/${url}`
      );

      if (!formulario.data.error) {
        dispatch(formularioObtenido(formulario.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

const guardarRespuestaEnvio = () => ({
  type: GUARDAR_RESPUESTAS_EXITO
});

const actualizaStateGuardado = () => ({
  type: ACTUALIZAR_GUARDADO
})

export const guardarRespuestasForm = (data) => {
  return async (dispatch) => {
    dispatch(loading(GUARDAR_RESPUESTAS));

    try {
      const respuesta = await authAxios.post(
        `/admin/rptet_insertarCalificacionDiscente`,
        data
      );
      console.log(respuesta.data)
      if (!respuesta.data.error) {
        dispatch(guardarRespuestaEnvio(respuesta.data));
        // dispatch(push('/form/nombre-encuesta-98f21b30-ad1c-11ea-93f7-5fa81159983e/success'))
        dispatch(actualizaStateGuardado())
        return
      }
      else{
        alert(respuesta.data.error)
      }
    } catch (error) {
      console.log(error)
    }
  };
};
