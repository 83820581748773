import Error404 from "../pages/Error404";
import Home from "../pages/Home";
import ExitoEnvio from "../pages/ExitoEnvio/ExitoEnvio";
import EncuestaDocente from "../pages/EncuestaDocente";
import EncuestaDiscente from "../pages/EncuestaDiscente/EncuestaDiscente";

export default [
  {
    path: '/form/:url',
    exact: true,
    page: EncuestaDiscente
  },
  {
    path: '/form/:url/success',
    exact: true,
    page: ExitoEnvio,
    private: true
  },
  {
    path: '*',
    exact: true,
    page: Error404
  }
]