import React from "react";
import Header from "../../components/Header";
import "./BasicLayout.scss";
import { Helmet } from "react-helmet";

const BasicLayout = (props) => {
  const { children, title, header=true } = props;

  return (
    <>
      <Helmet>
        <title>{`INEDI | ${title}`}</title>
        <link
          rel="stylesheet"
          href="https://necolas.github.io/normalize.css/8.0.1/normalize.css"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link rel="shortcut icon" href="/assets/images/icon_inedi2.png" />
        <meta name="description" content="Página para las encuestas - INEDI Posgrados"/>
      </Helmet>
        <div className="basic-layout">
        
        { header && <Header/>}

        <main>{children}</main>
      </div>
    </>
  );
};

export default BasicLayout;
