import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import './FormularioDocente.scss';
import ListaPreguntasDocentes from '../ListaPreguntasDocentes';

//Redux
import { connect } from 'react-redux';

const FormularioDocente = props => {

  const { formulario } = props

  const [dataFormDocente, setDataFormDocente] = useState({})

  const initialState = () => {
    const initial = {
      id_formulario: 1
    }

    let respuestas = new Array;

    formulario.preguntas.map(pregunta => {
      respuestas.push({
        id_pregunta_reporte: pregunta.id_pregunta_reporte,
        contenido: 0,
        id_tp_pregunta: pregunta.id_tp_pregunta,
        propuestas: pregunta.propuestas
      })
    })

    initial.respuestas = respuestas

    setDataFormDocente(initial)
  }

  useEffect(() => {
    initialState()
  }, [formulario])

  return ( 
    <div className="formulario-docente">
      <Form>
        <ListaPreguntasDocentes 
          formulario={formulario}
         />
        <Button>
          Enviar
        </Button>
      </Form>
    </div>
   );
}
 
const mapStateToProps = state => ({
  formulario: state.formulario.formulario
})

export default connect(mapStateToProps, {})(FormularioDocente);
